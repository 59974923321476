<template>
  <div>
    <div
      v-if="loading"
      class="parking"
      v-loading="loading"
      element-loading-text="结算中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    />
    <div v-else ref="main">
      <div class="parkingpay">
        <div class="top">
          <div class="topline"></div>
          <div class="topmsg">
            <div class="plateno-box">
              <div class="plateno">
                <div class="icon"><img src="../../assets/iconcar.png" /></div>
                <div class="num" v-if="parkData.plateNo">{{ parkData.plateNo }}</div>
              </div>
              <div class="contact" v-if="serviceTel !== null">
                <van-button round type="info" size="mini" @click="handleContact"> 联系客服 </van-button>
              </div>
            </div>
            <div class="cash">
              <div class="money"><i style="font-size: 18px">￥</i>{{ (paymentAmount * 1) | rounding }}</div>
              <div style="color: #a3a3a3; font-size: 15px">本单待付</div>
            </div>
          </div>
        </div>
        <div class="banner">
          <div class="top-foot">
            <div class="leftpoint"></div>
            <div class="line"></div>
            <div class="rightpoint"></div>
          </div>
          <div class="banner-msg">
            <div class="message" style="margin-bottom: 10px">
              <div>车场名称</div>
              <div style="color: #333333">{{ parkData.roadName }}</div>
            </div>
            <div class="message" style="margin-bottom: 10px">
              <div>泊位编号</div>
              <div style="color: #333333">{{ parkData.berthNo }}</div>
            </div>
            <div class="message" style="margin-bottom: 10px">
              <div>驶入时间</div>
              <div style="color: #333333">{{ parkData.startTime }}</div>
            </div>
            <div class="message" style="margin-bottom: 10px">
              <div>取证时间</div>
              <div style="color: #333333">{{ parkData.uplodaPdaTime }}</div>
            </div>
            <div class="message" style="margin-bottom: 10px">
              <div>驶出时间</div>
              <div style="color: #333333">{{ parkData.stopTime }}</div>
            </div>
            <div class="message" style="margin-bottom: 10px">
              <div>扫码时间</div>
              <div style="color: #333333">{{ parkData.scanDateTime }}</div>
            </div>
            <div class="message" style="margin-bottom: 10px">
              <div>停车时长</div>
              <div style="color: #333333">{{ parkData.parkTime }}</div>
            </div>
            <div class="message" style="margin-bottom: 10px">
              <div>订单金额<span style="font-size: 13px">(实际费用以车辆出场为准)</span></div>
              <div style="color: #2370ff">￥{{ (parkData.totalPay * 1) | rounding }}</div>
            </div>
            <div class="message">
              <div>已付金额</div>
              <div style="color: #2370ff">￥{{ (parkData.alreadPay * 1) | rounding }}</div>
            </div>
          </div>
          <div class="coupon">
            <van-cell
              class="coupon-box"
              title="优惠券"
              is-link
              :value="
                savePaymentAmount == paymentAmount
                  ? parkData.hasCoupon
                    ? '有可用券'
                    : '无可用券'
                  : '-￥' + nowDiscounts
              "
              @click="
                getCouponList(parkData.parkRecordId, parkData.shouldPay, parkData.orderId, 0, 0, parkData.startTime)
              "
            >
              <template #title>
                <span class="custom-title">
                  <img style="width: 18px; height: 18px; vertical-align: text-top" src="../../assets/coupon-icon.png" />
                  优惠券
                </span>
                <van-tag plain type="danger" v-if="claimable !== null && claimable !== '' ? true : false">
                  {{ claimable }}
                </van-tag>
              </template>
            </van-cell>
          </div>
          <van-checkbox-group v-model="selectList" ref="checkboxGroup" @change="allOrderChange">
            <!-- 实时订单  -->
            <div class="real-order" v-if="realParkRecord.parkId">
              <div class="real-title">
                <div>实时订单</div>
                <div style="color: #2370ff" @click="realParkDetail">详情<van-icon name="arrow" /></div>
              </div>
              <div class="real-msg">
                <div class="msg-box">
                  <div>车场名称</div>
                  <div class="value">{{ realParkRecord.roadName }}</div>
                </div>
                <div class="msg-box">
                  <div>泊位编号</div>
                  <div class="value">{{ realParkRecord.berthNos }}</div>
                </div>
                <div class="msg-box">
                  <div>驶入时间</div>
                  <div class="value">{{ realParkRecord.parkingTime }}</div>
                </div>
                <div class="msg-box">
                  <div>停车时间</div>
                  <div class="value">{{ realParkRecord.parkTimeLength }}</div>
                </div>
                <div class="msg-box">
                  <div>待付金额</div>
                  <div class="value" style="color: #2370ff">￥{{ (realParkRecord.shouldPay * 1) | rounding }}</div>
                </div>
              </div>
              <div class="check-coupon">
                <van-checkbox :name="realParkRecord" @click="handleOrderClick(realParkRecord.orderId)"></van-checkbox>
                <van-cell
                  class="coupon"
                  title="优惠券"
                  is-link
                  :value="
                    realParkRecord.amount == realParkRecord.oramount
                      ? realParkRecord.hasCoupon
                        ? '有可用券'
                        : '无可用券'
                      : '优惠 -￥' + realParkRecord.discounts
                  "
                  @click="
                    getCouponList(
                      realParkRecord.parkId,
                      realParkRecord.shouldPay,
                      realParkRecord.orderId,
                      realParkRecord.parkId,
                      1,
                      realParkRecord.parkingTime
                    )
                  "
                >
                  <template #title>
                    <span class="custom-title">本单待付：</span>
                    <span style="color: #2370ff">￥{{ (realParkRecord.shouldPay * 1) | rounding }}</span>
                  </template>
                </van-cell>
              </div>
            </div>
            <div class="arrear-order" v-if="parkData.arreas.length">
              <div class="arrear-title">
                <div>
                  历史欠费
                  <span style="color: #333333; font-weight: 100">
                    （<i style="color: #2370ff; font-weight: 600"> ￥{{ (parkData.totalArrea * 1) | rounding }} </i> /
                    {{ parkData.arreaCount }}笔）
                  </span>
                </div>
              </div>
              <div class="arrear-box" v-for="(park, index) in parkData.arreas" :key="park.id">
                <div v-show="index >= 3 && editNum == 2 ? false : true">
                  <div class="arrear-msg" @click="toDepDetail(park.id)">
                    <div class="msg-box">
                      <div>车场名称</div>
                      <div class="value">{{ park.parkingRecord.roadName }}</div>
                    </div>
                    <div class="msg-box">
                      <div>泊位编号</div>
                      <div class="value">{{ park.berthNo }}</div>
                    </div>
                    <div class="msg-box">
                      <div>驶入时间</div>
                      <div class="value">{{ park.parkingRecord.parkingTime }}</div>
                    </div>
                    <div class="msg-box">
                      <div>驶出时间</div>
                      <div class="value">{{ park.parkingRecord.parkoutTime }}</div>
                    </div>
                    <div class="msg-box">
                      <div>停车时间</div>
                      <div class="value">{{ park.parkingRecord.parkTime }}</div>
                    </div>
                    <div class="msg-box">
                      <div>欠费金额</div>
                      <div class="value" style="color: #2370ff">￥{{ (park.arrear * 1) | rounding }}</div>
                    </div>
                  </div>
                  <div class="check-coupon">
                    <van-checkbox :name="itemIds[index]" @click="handleOrderClick(park.orderId)"></van-checkbox>
                    <van-cell
                      class="coupon"
                      title="优惠券"
                      is-link
                      :value="
                        itemIds[index].amount == itemIds[index].oramount
                          ? itemIds[index].hasCoupon
                            ? '有可用券'
                            : '无可用券'
                          : '优惠 -￥' + itemIds[index].discounts
                      "
                      @click="
                        getCouponList(park.id, park.arrear, park.orderId, park.id, 2, park.parkingRecord.parkingTime)
                      "
                    >
                      <template #title>
                        <span class="custom-title">本单待付：</span>
                        <span style="color: #2370ff">￥{{ (itemIds[index].amount * 1) | rounding }}</span>
                      </template>
                    </van-cell>
                  </div>
                  <van-divider
                    :hairline="false"
                    dashed
                    :style="{ borderColor: '#E5E5E5' }"
                    style="margin: 0; padding: 15px 0"
                  />
                </div>
              </div>
              <div
                class="showall"
                v-if="parkData.arreas.length >= 3 && editNum == 2 ? true : false"
                @click="editNum = 3"
              >
                展示全部<van-icon name="arrow-down" />
              </div>
            </div>
          </van-checkbox-group>
        </div>
        <div class="foot-line"></div>
      </div>
      <!-- 底部支付 -->
      <div class="tab-bar">
        <van-checkbox style="width: 70px" v-model="checkAll" @click="handleCheckAllChange" v-if="checkedCount">
          全选
        </van-checkbox>
        <div>
          <div>
            <span>您需支付：</span>
            <span style="color: #2370ff"> ￥{{ (shouldPayPreCPU * 1) | rounding }} </span>
          </div>
          <div style="color: #eb5757; font-size: 13px">共优惠￥{{ (deduction * 1) | rounding }}</div>
        </div>
        <van-button
          :style="{
            'background-color': '#2370FF',
          }"
          @click="payWaysettlement"
        >
          支付
        </van-button>
      </div>
      <!-- 优惠券框 -->
      <van-popup
        v-model="couponshow"
        closeable
        round
        position="bottom"
        :style="{ height: activityList.length ? '80%' : allCouponList.length ? '70%' : '30%' }"
        class="coupon-popup"
        @click-close-icon="couponCancel"
      >
        <div class="getcoupon-list" v-if="activityList.length">
          <div class="getcoupon-title">
            <img src="../../assets/getcoupon-title.png" />
          </div>
          <div class="getcoupon-view" v-for="(item, index) in activityList" :key="index">
            <div class="left">
              <div class="getcoupon-view-top">{{ item.name }}</div>
              <div class="getcoupon-view-middle">
                {{
                  item.type == 1
                    ? "授权手机号注册会员，体验更多功能"
                    : item.type == 2
                    ? "关注企业公众号，停车有位更优惠"
                    : item.type == 3
                    ? "停车订单在线支付即可领取"
                    : item.type == 4
                    ? "回馈新老用户，免费领券，限量供应"
                    : "免费领取优惠券"
                }}
              </div>
              <div class="getcoupon-view-bottom">
                <div class="coupon-count" v-for="(list, index) in item.bzEquitiesGoodsList" :key="index">
                  <div class="coupon-count-left">{{ list.typeToString }}</div>
                  <div class="coupon-count-right">{{ list.valueToString }}</div>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="getbtn" @click="getActiveityCoupon(item.type, item.id)">
                <div>
                  {{
                    item.type == 1
                      ? "注册"
                      : item.type == 2
                      ? "关注"
                      : item.type == 3
                      ? "支付"
                      : item.type == 4
                      ? "免费"
                      : "立刻"
                  }}
                </div>
                <div>领取</div>
              </div>
            </div>
          </div>
        </div>
        <div class="gap" v-if="activityList.length"></div>
        <div class="coupon-list">
          <div v-if="allCouponList.length">
            <div class="coupon-title">可用优惠券</div>
            <van-divider style="margin: 0; padding: 15px 0" :hairline="false" />
            <div class="checkmsg">
              您已选择{{ checkCouponList.length }}张优惠券，可抵扣<span style="color: #ff7627">
                {{ "￥" + deduction }}
              </span>
            </div>
            <van-radio-group v-model="couponCheck" @change="changeRad">
              <div v-for="(item, index) in allCouponList" :key="index">
                <div class="coupon-view" v-show="item.isShow" :class="{ active: couponClickIndex == index }">
                  <div class="expired-label" v-if="item.beAlmostExpired">
                    <img src="../../assets/coupon-expired-label.png" alt="" />
                  </div>
                  <div class="semi-circle-top" :class="{ 'active-topround': couponClickIndex == index }"></div>
                  <div class="semi-circle-bottom" :class="{ 'active-bottomround': couponClickIndex == index }"></div>
                  <div class="coupon-type">{{ couponTypeList[item.type] }}</div>
                  <div class="coupon-msg">
                    <div class="coupon-favour-left" v-if="item.activityId">
                      <div v-if="item.type !== 2">
                        <div style="font-size: 30px">
                          {{ item.value }}
                          <span style="font-size: 15px">
                            {{
                              item.type == 1
                                ? "元"
                                : item.type == 2
                                ? "免单"
                                : item.type == 3
                                ? "元"
                                : item.type == 4
                                ? "折"
                                : ""
                            }}
                          </span>
                        </div>
                        <div>{{ item.conditionToString }}</div>
                      </div>
                      <div class="coupon-favour-left" v-else>
                        <div style="font-size: 18px; font-weight: 550">免单体验</div>
                        <div>({{ item.valueToString }})</div>
                        <div>{{ item.conditionToString }}</div>
                      </div>
                    </div>
                    <div class="coupon-favour-left" v-else>
                      <div style="font-size: 30px">
                        {{ item.value }}
                        <span style="font-size: 15px">{{ item.type == 1 ? "元" : item.type == 6 ? "折" : "" }}</span>
                      </div>
                      <div>商家优惠券</div>
                    </div>
                    <div class="coupon-favour-right">
                      <div class="favour-dsc">{{ item.equitiesName }}</div>
                      <div class="select">
                        <van-radio :name="item.id" checked-color="#FFA763" @click="radClick(index)"></van-radio>
                      </div>
                      <div class="favour-date">
                        <div>
                          有效期至：
                          {{ item.endTime }}
                        </div>
                        <!-- <div>详情<van-icon name="arrow" /></div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </van-radio-group>
            <van-divider>暂无更多</van-divider>
          </div>
          <van-empty v-else description="暂无可用优惠券" />
        </div>
        <div class="coupon-define" v-if="allCouponList.length">
          <van-button round block class="cancel" @click="couponCancel">取消</van-button>
          <van-button round block class="submit" @click="couponsumbit">确定</van-button>
        </div>
      </van-popup>
    </div>

    <SigninCouponAlert :sendshow="signinShow" @destroyed="alertDestroyed(1)"></SigninCouponAlert>
    <GetCouponAlert :sendshow="getCouponShow" @destroyed="alertDestroyed(4)"></GetCouponAlert>
    <GetSuccess :sendshow="getSuccessShow" @destroyed="alertDestroyed(23)"></GetSuccess>
  </div>
</template>

<script>
import _ from "lodash";
import { Dialog } from "vant";
import dayjs from "dayjs";
import * as math from "mathjs";
import SigninCouponAlert from "../../component/signinCouponAlert";
import GetCouponAlert from "../../component/getCouponAlert";
import GetSuccess from "../../component/getSuccess";

export default {
  name: "Parkingpay",
  components: {
    [Dialog.Component.name]: Dialog.Component,
    SigninCouponAlert,
    GetCouponAlert,
    GetSuccess,
  },
  data() {
    return {
      loading: true,
      checkAll: true,
      // 选择的值
      checkedPark: [],
      // 详情控制
      detailsedit: false,
      form: {},
      // 停车数据
      parkData: {},
      // 结算按钮禁用控制
      isOn: false,
      // 欠费的列表
      itemIds: [],
      // 保存的原本金额
      cash: 0,
      // 代付金额不改变
      paymentAmount: 0,
      savePaymentAmount: 0,
      // 欠费的总金额
      arrearPay: 0,
      // 展示优惠券
      couponshow: false,
      // 选择优惠券
      couponCheck: "",
      // 所有优惠券列表
      allCouponList: [],
      // 选中的优惠券
      checkCouponList: [],
      // 最佳优惠券
      bestCoupon: {},
      // 实时订单数据
      realParkRecord: {},
      realParkRecordCheck: [],
      allCountLength: null,
      // 重构后的参数
      // 所有需要勾选的订单的长度
      checkedCount: 0,
      selectList: [],
      // 欠费全部展示
      editNum: null,
      // 注册优惠券是否展示
      signinShow: false,
      // 领券是否展示
      getCouponShow: false,
      // 领取成功是否展示
      getSuccessShow: false,
      couponTypeList: {
        1: "现金券",
        2: "免单券",
        3: "满减券",
        4: "折扣券",
        6: "折扣券",
      },
      // 选择的展示优惠券的订单号
      checkOrderId: null,
      // 选中的订单id
      checkParkId: null,
      // 可领券
      claimable: null,
      // 活动券列表
      activityList: [],
      // 优惠券选中下标
      couponClickIndex: -1,
      // 优惠券选中变化
      couponRaidChange: false,
      // 驻车器
      isBrake: localStorage.getItem("isBrake"),
      // 客服电话
      serviceTel: null,
      domain: process.env.VUE_APP_DOMAIN,
    };
  },
  async mounted() {
    await this.getList();
    if (this.isBrake == 1) {
      Dialog.alert({
        message: `请确保驻车器下降完成再驶离!`,
      }).then(() => {
        // on close
      });
    }
  },
  watch: {
    // signinShow: {
    //   handler(val) {
    //     val ? (this.$refs.main.style.filter = `blur(2px)`) : (this.$refs.main.style.filter = null);
    //   },
    // },
    // getCouponShow: {
    //   handler(val) {
    //     val ? (this.$refs.main.style.filter = `blur(2px)`) : (this.$refs.main.style.filter = null);
    //   },
    // },
  },
  computed: {
    // 合计支付金额
    shouldPayPreCPU() {
      let selectAmount = this.selectList.length
        ? this.selectList.reduce((accumulator, currentValue) => {
            const valueToAdd = currentValue.shouldPay;
            return math.add(math.bignumber(accumulator), math.bignumber(valueToAdd));
          }, 0)
        : 0;
      return math.add(math.bignumber(this.savePaymentAmount), math.bignumber(selectAmount));
    },
    // 抵扣金额
    deduction() {
      return this.checkCouponList.length
        ? this.checkCouponList.reduce((accumulator, currentValue) => {
            const valueToAdd = currentValue.lastFee;
            return math.add(math.bignumber(accumulator), math.bignumber(valueToAdd));
          }, 0)
        : 0;
    },
    // 当前订单的支付金额
    nowDiscounts() {
      return math.subtract(math.bignumber(this.paymentAmount), math.bignumber(this.savePaymentAmount));
    },
  },
  methods: {
    // 联系客服
    handleContact() {
      window.location.href = `tel:${this.serviceTel}`;
    },
    // 优惠券框选择变化
    changeRad() {
      this.couponRaidChange = true;
    },
    // 优惠券选择
    radClick(index) {
      this.couponClickIndex = index;
      if (!this.couponRaidChange) {
        this.couponCheck = "";
        this.couponClickIndex = -1;
      }
      this.couponRaidChange = false;
    },
    // 领取弹框关闭感知
    alertDestroyed(type) {
      let appId = localStorage.getItem("appId");
      let itemId = localStorage.getItem("itemId");
      let openId = localStorage.getItem("openId");
      this.getList();
      // 查询是否可领券
      this.$axios
        .post(`${this.domain}/api/opercenter/queryBzActivityOfParkPayAccessibleLabel`, {
          appId,
          payAmount: this.cash,
          itemId,
          openId: openId ? openId : null,
        })
        .then((res) => {
          if (res.data.data.availableEquities) {
            this.claimable = res.data.data.availableEquities;
          }
        });
      if (type == 1) {
        this.signinShow = false;
      }
      if (type == 4) {
        this.getCouponShow = false;
      }
    },
    // 活动券领取
    getActiveityCoupon(type, id) {
      this.couponshow = false;
      if (type == 1) {
        this.signinShow = true;
        this.getCouponShow = false;
        this.getSuccessShow = false;
      }
      if (type == 2) {
        let appId = localStorage.getItem("appId");
        let openId = localStorage.getItem("openId");
        let alipayuserId = localStorage.getItem("alipayuserId");
        this.$axios.post(`${this.domain}/api/opercenter/disposeFocusOfficial`, {
          appId,
          openId: openId ? openId : alipayuserId,
          id,
        });
        this.$axios
          .post(`${this.domain}/api/opercenter/queryUninBase64`, {
            appId,
          })
          .then((res) => {
            let url = `https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=${res.data.data}&scene=110#wechat_redirect`;
            location.href = url;
          });
      }
      if (type == 3) {
        this.$toast("支付订单即可领取到优惠券");
      }
      if (type == 4) {
        let sign = this.activityList.filter((item) => item.type === 1);
        let follow = this.activityList.filter((item) => item.type === 2);
        if (sign.length > 0) {
          this.$toast("请先领取用户注册领取优惠券活动");
        } else if (follow.length > 0) {
          this.$toast("请先领取用户关注公众号领取优惠券活动");
        } else {
          this.signinShow = false;
          this.getCouponShow = true;
          this.getSuccessShow = false;
        }
      }
    },
    // 点击勾选或取消勾选订单
    handleOrderClick(orderId) {
      let newList = this.selectList.filter((item) => item.orderId === orderId);
      if (newList.length == 0) {
        // 如果有优惠券取消勾选后删除掉
        const existingIndex = this.checkCouponList.findIndex((item) => item.orderId === orderId);
        if (existingIndex !== -1) {
          // 如果存在相同 orderId 的对象，先删除旧的对象
          this.checkCouponList.splice(existingIndex, 1);
        }
        // 查看欠费订单是否取消勾选后存在相同的订单，将金额初始化
        this.itemIds.forEach((item) => {
          if (item.orderId === orderId) {
            item.amount = item.oramount;
          }
        });
        // 查看实时订单是否相同，将金额初始化
        if (this.realParkRecord.orderId === orderId) {
          this.realParkRecord.amount = this.realParkRecord.oramount;
        }
      }
    },
    // 监听勾选订单改变
    allOrderChange(val) {
      this.realParkRecordCheck = [];
      this.checkedPark = [];
      val.forEach((item) => {
        if (item.itemId) {
          this.realParkRecordCheck.push(item);
        } else {
          this.checkedPark.push(item);
        }
      });
      if (val.length == this.checkedCount) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
    },
    // 实时订单详情
    realParkDetail() {
      let { realParkRecord } = this;
      this.$router.push({
        name: "Realparkrecord",
        params: realParkRecord,
      });
    },
    // 优惠券选择取消
    couponCancel() {
      this.couponshow = false;
      this.couponCheck = "";
      this.checkOrderId = null;
      this.checkParkId = null;
    },
    // 优惠券选择确定
    couponsumbit() {
      if (this.couponCheck !== "") {
        let afterFee = this.allCouponList.filter((item) => item.id === this.couponCheck)[0].afterFee;
        let lastFee = this.allCouponList.filter((item) => item.id === this.couponCheck)[0].lastFee;
        let ticketMark = this.allCouponList.filter((item) => item.id === this.couponCheck)[0].ticketMark;
        if (this.checkCouponList.length) {
          let exists = false;
          for (const item of this.checkCouponList) {
            if (item.id * 1 === this.couponCheck * 1) {
              exists = true;
              break;
            }
          }
          if (!exists) {
            // 在添加新对象之前检查是否存在相同 orderId 的对象
            const existingIndex = this.checkCouponList.findIndex((item) => item.orderId === this.checkOrderId);
            if (existingIndex !== -1) {
              // 如果存在相同 orderId 的对象，先删除旧的对象
              this.checkCouponList.splice(existingIndex, 1);
            }
            this.checkCouponList.push({
              id: this.couponCheck,
              orderId: this.checkOrderId,
              parkId: this.checkParkId,
              afterFee,
              lastFee,
              ticketMark,
            });
          }
        } else {
          this.checkCouponList.push({
            id: this.couponCheck,
            orderId: this.checkOrderId,
            parkId: this.checkParkId,
            afterFee,
            lastFee,
            ticketMark,
          });
        }
        // 是当前订单还是实时或欠费订单
        if (this.checkOrderId === this.parkData.orderId) {
          // 初始化金额
          this.savePaymentAmount = this.paymentAmount;
          this.savePaymentAmount = this.checkCouponList.filter(
            (item) => item.orderId === this.parkData.orderId
          )[0].afterFee;
        } else {
          // 将金额还原为初始金额，以防订单重新选择了其他优惠券导致金额不对
          this.selectList.forEach((item) => {
            item.amount = item.oramount;
            item.discounts = null;
          });
          // 将使用优惠券之后需要支付的价格修改便于计算相加
          this.selectList.forEach((item) => {
            const commonObj = this.checkCouponList.find((list) => list.orderId === item.orderId);
            if (commonObj) {
              // 修改使用了优惠券之后的值
              item.amount = commonObj.afterFee;
              item.discounts = math.subtract(math.bignumber(item.oramount), math.bignumber(commonObj.afterFee));
            }
          });
        }
      } else {
        // 判断用户是否取消勾选优惠券
        const existingIndex = this.checkCouponList.findIndex((item) => item.orderId === this.checkOrderId);
        if (existingIndex !== -1) {
          // 如果存在相同 orderId 的对象，先删除旧的对象
          this.checkCouponList.splice(existingIndex, 1);
        }
        if (this.checkOrderId === this.parkData.orderId) {
          // 初始化金额
          this.savePaymentAmount = this.paymentAmount;
        } else {
          // 恢复原来金额
          this.selectList.forEach((item) => {
            if (item.orderId === this.checkOrderId) {
              item.amount = item.oramount;
            }
          });
        }
      }
      this.couponshow = false;
    },
    couponEdit() {
      this.couponshow = true;
    },
    // 优惠券查询
    getCouponList(parkRecordId, payAmount, orderId, id, type, parkingTime) {
      this.couponClickIndex = -1;
      // 判断订单是否已经勾选
      const isSelect = this.selectList.findIndex((item) => item.parkId === id);
      if (isSelect !== -1 || type == 0) {
        this.couponCheck = "";
        this.checkOrderId = orderId;
        this.checkParkId = parkRecordId;
        this.$toast.loading({
          duration: 0,
          message: "加载中...",
          forbidClick: true,
          loadingType: "spinner",
        });
        let openId = localStorage.getItem("openId");
        let alipayuserId = localStorage.getItem("alipayuserId");
        let appId = localStorage.getItem("appId");
        let itemId = localStorage.getItem("itemId");
        this.$axios
          .post(`${this.domain}/api/charge/bestOffer`, {
            appId,
            itemId,
            parkRecordId,
            plateNo: this.parkData.plateNo,
            monthFee: payAmount,
            scanningTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
            type: 0,
            openId: openId ? openId : null,
            payAmount,
            outTradeNo: orderId,
            parkingTime,
          })
          .then((res) => {
            if (res.data.data !== null) {
              let useCouponList = [];
              if (res.data.data.activityEquitiesGoods.length) {
                let keyMapping = {
                  equitiesType: "type",
                  value: "value",
                  equitiesName: "equitiesName",
                  validityTime: "endTime",
                  code: "ticketMark",
                  deduction: "lastFee",
                };
                useCouponList = res.data.data.activityEquitiesGoods.map((obj) => this.mapKeys(obj, keyMapping));
                // 转换id值，因原本就存在id，上述方法并不能转换
                useCouponList.forEach((item) => {
                  item.id = item.ticketMark;
                });
              }
              res.data.data.ticketInfos = res.data.data.ticketInfos.concat(useCouponList);
              if (res.data.data.ticketInfos.length) {
                for (const addedItem of res.data.data.ticketInfos) {
                  const matchingItem = this.checkCouponList.find((item) => item.id === addedItem.id);
                  if (matchingItem) {
                    // 如果找到匹配的项
                    if (matchingItem.orderId === orderId) {
                      // 如果orderId也相同，将 isShow 设置为 true
                      addedItem.isShow = true;
                      this.couponCheck = matchingItem.id;
                      setTimeout(() => {
                        this.radClick(res.data.data.ticketInfos.indexOf(addedItem));
                      }, 0);
                    } else {
                      // 如果orderId不相同，将 isShow 设置为 false
                      addedItem.isShow = false;
                    }
                  } else {
                    // 如果没找到匹配的项，将 isShow 设置为 true
                    addedItem.isShow = true;
                  }
                }
                this.allCouponList = res.data.data.ticketInfos;
              }
            }
          })
          .then((res) => {
            // 活动列表
            this.$axios
              .post(`${this.domain}/api/opercenter/queryBzActivityOfParkPay`, {
                appId,
                openId: openId ? openId : null,
              })
              .then((res) => {
                if (res.data.data) {
                  this.activityList = res.data.data;
                  // 活动领取反馈
                  this.$axios.post(`${this.domain}/api/opercenter/queryActivityOfH5FeedBack`, {
                    appId,
                    openId: openId ? openId : null,
                  });
                }
                this.$toast.clear();
                this.couponshow = true;
              })
              .catch((e) => {
                alert(JSON.stringify(e));
                this.$toast.clear();
              });
          })
          .catch((e) => {
            alert(JSON.stringify(e));
            this.$toast.clear();
            this.$toast.fail("加载失败");
          });
      } else {
        this.$toast("请先勾选当前订单");
      }
    },
    // 转换优惠券参数名
    mapKeys(obj, keyMap) {
      return Object.keys(obj).reduce((acc, key) => {
        acc[keyMap[key] || key] = obj[key];
        return acc;
      }, {});
    },
    getList() {
      let parkRecordId = localStorage.getItem("parkRecordId");
      let itemId = localStorage.getItem("itemId");
      let appId = localStorage.getItem("appId");
      let openId = localStorage.getItem("openId");
      let alipayuserId = localStorage.getItem("alipayuserId");
      this.$axios
        .post(`${this.domain}/api/opercenter/queryActivityOfH5FeedBack`, {
          appId,
          openId: openId ? openId : null,
        })
        .then((res) => {
          this.$axios
            .post(`${this.domain}/api/charge/chargingForH5`, {
              parkRecordId,
              openId,
            })
            .then((res) => {
              this.parkData = res.data.data;
              this.paymentAmount = res.data.data.shouldPay;
              this.savePaymentAmount = res.data.data.shouldPay;
              this.cash = res.data.data.shouldPay;
              // 判断是否展示三条欠费
              res.data.data.arreas.length > 3 ? (this.editNum = 2) : (this.editNum = 3);
              if (res.data.data.arreas) {
                this.itemIds = res.data.data.arreas.map((item) => ({
                  parkId: item.id,
                  amount: item.arrear,
                  oramount: item.arrear,
                  shouldPay: item.arrear,
                  discounts: null,
                  orderId: item.orderId,
                  hasCoupon: item.hasCoupon,
                  parkRecordId: item.parkingRecord.parkId,
                }));
                // 保存欠费列表
                this.checkedPark = JSON.parse(JSON.stringify(this.itemIds));
              }
              if (res.data.data.realParkRecord && res.data.data.realParkRecord !== null) {
                // 处理泊位号
                res.data.data.realParkRecord.berthNos = JSON.parse(res.data.data.realParkRecord.berthNos).join();
                // 保存实时订单
                this.realParkRecord = res.data.data.realParkRecord;
                this.realParkRecord["oramount"] = res.data.data.realParkRecord.amount;
                this.realParkRecord["discounts"] = null;
                // 初始化选择状态
                this.realParkRecordCheck.push(res.data.data.realParkRecord);
              }
              this.checkedCount = res.data.data.arreas.length + this.realParkRecordCheck.length;
              this.$nextTick(() => {
                setTimeout(() => {
                  this.$refs.checkboxGroup.toggleAll(true);
                }, 0);
              });
              // 查询是否可领券
              this.$axios
                .post(`${this.domain}/api/opercenter/queryBzActivityOfParkPayAccessibleLabel`, {
                  appId,
                  payAmount: res.data.data.shouldPay,
                  itemId,
                  openId: openId ? openId : null,
                })
                .then((res) => {
                  if (res.data.data.availableEquities) {
                    this.claimable = res.data.data.availableEquities;
                  }
                });
              // 查询客服电话
              this.$axios
                .post(`${this.domain}/applet/customer-service-number`, {
                  appId,
                })
                .then((res) => {
                  if (res.data.data !== null) {
                    this.serviceTel = res.data.data[0].phoneNumber;
                  }
                });
              this.loading = false;
            });
        });
    },
    // 全选
    handleCheckAllChange(e) {
      this.checkAll ? this.$refs.checkboxGroup.toggleAll(true) : this.$refs.checkboxGroup.toggleAll(false);
      !this.checkAll && this.itemIds.forEach((item) => this.handleOrderClick(item.orderId));
    },
    // 下单
    payRequest() {
      let openId = localStorage.getItem("openId");
      let userId = localStorage.getItem("alipayuserId");
      let appId = localStorage.getItem("appId");
      let itemId = localStorage.getItem("itemId");
      let pdaNo = localStorage.getItem("pdaNo");
      let realFee = this.realParkRecordCheck.length ? this.realParkRecordCheck[0].shouldPay : 0;
      let realNos =
        this.realParkRecordCheck.length && this.realParkRecordCheck[0].shouldPay !== 0
          ? this.realParkRecordCheck[0].parkId
          : null;
      this.$axios
        .get(`${this.domain}/api/jinhua/select/${appId}/${itemId}`)
        .then((res) => {
          if (res.data.data !== null) {
            let ids = [];
            let nos = "";
            let arrea_fee = null;
            if (this.checkedPark.length) {
              this.checkedPark.forEach((item) => {
                ids.push(item.parkId);
              });
              nos = ids.join(",");
              arrea_fee = this.checkedPark.reduce((accumulator, currentValue) => {
                const valueToAdd = currentValue.amount;
                return math.add(math.bignumber(accumulator), math.bignumber(valueToAdd));
              }, 0);
              arrea_fee = Number(arrea_fee);
            }
            this.$axios
              .post(`${this.domain}/api/jinhua/road_pay`, {
                appId,
                itemId,
                mchNo: this.parkData.orderId,
                clientIp: "127.0.0.1",
                totalFee: this.cash,
                parkRecordId: this.parkData.parkRecordId,
                plateNo: this.parkData.plateNo,
                nos,
                arrearFee: arrea_fee || 0,
                method: openId ? 1 : 2,
                realNos,
                realFee,
                pdaNo,
              })
              .then((res) => {
                window.location.href = res.data.data;
              });
          } else {
            if (openId) {
              this.wxSettlement();
            }
            if (userId) {
              this.aliSettlement();
            }
          }
        })
        .catch((e) => {
          alert(JSON.stringify(e));
        });
    },
    // 结算后使用
    handlePayAfter() {
      let openId = localStorage.getItem("openId");
      let userId = localStorage.getItem("alipayuserId");
      let parkId = this.realParkRecord.parkId ? this.realParkRecord.parkId : this.parkData.parkRecordId;
      // 判断小票是否为历史订单并且当前有订单在进行
      if (this.realParkRecord.parkId) {
        if (
          this.realParkRecordCheck.length &&
          this.realParkRecordCheck[0].shouldPay == 0 &&
          this.parkData.shouldPay == 0 &&
          this.selectList.length == 0
        ) {
          this.$axios.get(`${this.domain}/api/charge/zeroPayConfig?parkId=${parkId}`).then((res) => {
            // 是否开启0元支付
            if (res.data.data) {
              this.$toast.clear();
              Dialog.confirm({
                message: "是否确认离场?",
              })
                .then(() => {
                  this.$axios
                    .get(`${this.domain}/api/charge/freeTimePaymentOf0Yuan?parkId=${parkId}`)
                    .then((res) => {
                      if (openId) {
                        WeixinJSBridge.call("closeWindow");
                      }
                      if (userId) {
                        AlipayJSBridge.call("closeWebview");
                      }
                    })
                    .catch((e) => {
                      this.$toast.clear();
                      Dialog.alert({
                        message: `离场失败，请联系客服`,
                      }).then(() => {
                        // on close
                      });
                    });
                })
                .catch(() => {
                  // on cancel
                });
            } else {
              this.$toast.clear();
              Dialog.alert({
                message: `${res.data.msg}`,
              }).then(() => {
                // on close
              });
            }
          });
        } else {
          this.payRequest();
        }
      } else {
        if (this.parkData.shouldPay == 0 && this.parkData.totalPay == 0 && this.selectList.length == 0) {
          // 驻车器
          if (this.isBrake == 1) {
            this.$axios
              .post(`${this.domain}/api/brake/down`, {
                parkId: this.parkData.parkRecordId,
              })
              .then((res) => {
                Dialog.confirm({
                  message: `当前订单已结束，请确保驻车器下降后再驶离`,
                })
                  .then(() => {})
                  .catch(() => {
                    // on cancel
                  });
              })
              .catch((e) => {
                Dialog.confirm({
                  message: `当前订单处理失败，请联系管理员`,
                })
                  .then(() => {})
                  .catch(() => {
                    // on cancel
                  });
              });
          } else {
            this.$axios.get(`${this.domain}/api/charge/zeroPayConfig?parkId=${parkId}`).then((res) => {
              // 是否开启0元支付
              if (res.data.data) {
                this.$toast.clear();
                Dialog.confirm({
                  message: "是否确认离场?",
                })
                  .then(() => {
                    this.$axios
                      .get(`${this.domain}/api/charge/freeTimePaymentOf0Yuan?parkId=${parkId}`)
                      .then((res) => {
                        if (openId) {
                          WeixinJSBridge.call("closeWindow");
                        }
                        if (userId) {
                          AlipayJSBridge.call("closeWebview");
                        }
                      })
                      .catch((e) => {
                        this.$toast.clear();
                        Dialog.alert({
                          message: `离场失败，请联系客服`,
                        }).then(() => {
                          // on close
                        });
                      });
                  })
                  .catch(() => {
                    // on cancel
                  });
              } else {
                this.$toast.clear();
                Dialog.alert({
                  message: `${res.data.msg}`,
                }).then(() => {
                  // on close
                });
              }
            });
          }
        } else if (this.parkData.shouldPay == 0 && this.parkData.totalPay > 0 && this.selectList.length == 0) {
          this.$toast.clear();
          Dialog.alert({
            message: `当前订单已支付，请尽快离场`,
          }).then(() => {
            // on close
          });
        } else {
          this.payRequest();
        }
      }
    },
    // 结算按钮
    payWaysettlement: _.debounce(function () {
      this.$toast.loading({
        duration: 0,
        message: "支付中...",
        forbidClick: true,
        loadingType: "spinner",
      });
      let parkRecordId = localStorage.getItem("parkRecordId");
      let openId = localStorage.getItem("openId");
      this.$axios
        .post(`${this.domain}/api/charge/chargingForH5`, {
          parkRecordId,
          openId,
        })
        .then((res) => {
          if (this.paymentAmount !== res.data.data.shouldPay) {
            this.parkData = res.data.data;
            this.paymentAmount = res.data.data.shouldPay;
            this.savePaymentAmount = res.data.data.shouldPay;
            this.cash = res.data.data.shouldPay;
            let newList = this.checkCouponList.filter((item) => item.orderId === res.data.data.orderId);
            if (newList.length) {
              // 在添加新对象之前检查是否存在相同 orderId 的对象
              const existingIndex = this.checkCouponList.findIndex((item) => item.orderId === res.data.data.orderId);
              if (existingIndex !== -1) {
                // 如果存在相同 orderId 的对象，先删除旧的对象
                this.checkCouponList.splice(existingIndex, 1);
              }
              Dialog.alert({
                message: `请重新选择当前在场车辆订单优惠券`,
              }).then(() => {
                // on close
              });
            } else {
              this.handlePayAfter();
            }
          } else if (
            res.data.data.realParkRecord &&
            res.data.data.realParkRecord !== null &&
            this.realParkRecord.shouldPay !== res.data.data.realParkRecord.shouldPay
          ) {
            this.realParkRecord = res.data.data.realParkRecord;
            let newList = this.checkCouponList.filter((item) => item.orderId === res.data.data.realParkRecord.orderId);
            if (newList.length) {
              // 在添加新对象之前检查是否存在相同 orderId 的对象
              const existingIndex = this.checkCouponList.findIndex(
                (item) => item.orderId === res.data.data.realParkRecord.orderId
              );
              if (existingIndex !== -1) {
                // 如果存在相同 orderId 的对象，先删除旧的对象
                this.checkCouponList.splice(existingIndex, 1);
              }
              Dialog.alert({
                message: `请重新选择当前在场车辆订单优惠券`,
              }).then(() => {
                // on close
              });
            } else {
              this.handlePayAfter();
            }
          } else {
            this.handlePayAfter();
          }
          // 检查当前订单是否有使用优惠券，金额重置
        })
        .catch((e) => {
          this.$toast.clear();
        });
    }, 100),

    // 支付宝结算
    aliSettlement() {
      let userId = localStorage.getItem("alipayuserId");
      let itemId = localStorage.getItem("itemId");
      let pdaNo = localStorage.getItem("pdaNo");
      let nos = [];
      let arrea_fee = null;
      if (this.checkedPark.length) {
        arrea_fee = this.checkedPark.reduce((accumulator, currentValue) => {
          const valueToAdd = currentValue.amount;
          return math.add(math.bignumber(accumulator), math.bignumber(valueToAdd));
        }, 0);
        arrea_fee = Number(arrea_fee);
      }
      let realFee = this.realParkRecordCheck.length ? this.realParkRecordCheck[0].shouldPay : 0;
      let realNos = this.realParkRecordCheck.length ? this.realParkRecordCheck[0].parkId : null;
      let resultArray = [];
      let diffArray = [];
      if (this.checkedPark.length) {
        // 用了优惠券列表
        resultArray = this.checkCouponList.filter((item1) =>
          this.checkedPark.some((item2) => item2.orderId === item1.orderId)
        );
        // 没用优惠券列表
        diffArray = this.checkedPark.filter((item) => !resultArray.some((item1) => item1.orderId === item.orderId));
      }
      let useCouponList = [];
      let unUseCouponList = [];
      if (resultArray.length) {
        useCouponList = resultArray.map((item) => ({
          parkRecordId: item.parkId + "",
          code: item.id,
          deduction: item.lastFee,
        }));
      }
      if (diffArray.length) {
        unUseCouponList = diffArray.map((item) => ({
          parkRecordId: item.parkId + "",
          code: 0,
          deduction: 0,
        }));
      }
      nos = useCouponList.concat(unUseCouponList);
      let deductions =
        (this.checkCouponList.filter((item) => item.orderId === this.parkData.orderId).length
          ? this.checkCouponList.filter((item) => item.orderId === this.parkData.orderId)[0].lastFee
          : "0") +
        "," +
        (this.checkCouponList.filter((item) => item.orderId === this.realParkRecord.orderId).length
          ? this.checkCouponList.filter((item) => item.orderId === this.realParkRecord.orderId)[0].lastFee
          : "0") +
        (resultArray.length
          ? "," + resultArray.map((item) => item.lastFee).join(",")
          : this.checkedPark.length
          ? "," + Array(this.checkedPark.length).fill(0).join(",")
          : "");
      // 优惠券id
      let codes =
        (this.checkCouponList.filter((item) => item.orderId === this.parkData.orderId).length
          ? this.checkCouponList.filter((item) => item.orderId === this.parkData.orderId)[0].id
          : "0") +
        "," +
        (this.checkCouponList.filter((item) => item.orderId === this.realParkRecord.orderId).length
          ? this.checkCouponList.filter((item) => item.orderId === this.realParkRecord.orderId)[0].id
          : "0") +
        (resultArray.length
          ? "," + resultArray.map((item) => item.id).join(",")
          : this.checkedPark.length
          ? "," + Array(this.checkedPark.length).fill(0).join(",")
          : "");
      if (this.parkData.plateNo) {
        this.$axios
          .post(`${this.domain}/api/pay/aliPay`, {
            appId: this.parkData.appId,
            itemId,
            ifCode: "alipay",
            mchNo: this.parkData.orderId,
            userId,
            body: "停车缴费",
            notify_url: "http://www.baidu.com",
            clientIp: "127.0.0.1",
            totalFee: this.savePaymentAmount,
            parkRecordId: this.parkData.parkRecordId,
            plate_no: this.parkData.plateNo,
            arrearList: nos.length ? JSON.stringify(nos) : null,
            arrearFee: arrea_fee || 0,
            realFee,
            realNos,
            pdaNo,
            deductions: deductions == "" ? null : deductions,
            codes: codes == "" ? null : codes,
          })
          .then((res) => {
            this.$toast.clear();
            if (res.data.code === 200) {
              this.tradePay(`${res.data.trade_no}`);
            } else if (res.data.code === 9999) {
              Dialog.confirm({
                message: `${res.data.msg}`,
              })
                .then(() => {
                  AlipayJSBridge.call("closeWebview");
                })
                .catch(() => {
                  // on cancel
                });
            } else {
              Dialog.confirm({
                message: `${res.data.msg}`,
              })
                .then(() => {
                  this.$toast.clear();
                })
                .catch(() => {
                  // on cancel
                });
            }
          })
          .catch((error) => {
            this.$toast.clear();
            alert(error);
          });
      } else {
        this.$axios
          .post(`${this.domain}/api/brake/unifierOrder`, {
            appId: this.parkData.appId,
            itemId,
            openId: userId,
            money: this.cash,
            parkId: this.parkData.parkRecordId,
            payCode: "alipay",
          })
          .then((res) => {
            this.$toast.clear();
            let data = res.data.data.trade_no;
            this.tradePay(data);
          })
          .catch((e) => {
            this.$toast.clear();
          });
      }
    },
    // 支付宝收银台
    // 由于js的载入是异步的，所以可以通过该方法，当AlipayJSBridgeReady事件发生后，再执行callback方法
    ready(callback) {
      if (window.AlipayJSBridge) {
        callback && callback();
      } else {
        document.addEventListener("AlipayJSBridgeReady", callback, false);
      }
    },
    tradePay(tradeNO) {
      this.ready(() => {
        // 通过传入交易号唤起快捷调用方式(注意tradeNO大小写严格)
        AlipayJSBridge.call(
          "tradePay",
          {
            tradeNO: tradeNO,
          },
          (data) => {
            if (data.resultCode === "9000") {
              AlipayJSBridge.call("closeWebview");
            } else if (data.resultCode === "6001") {
              let parkRecordId = localStorage.getItem("parkRecordId");
              let payRecordIds =
                parkRecordId +
                (this.realParkRecordCheck.length ? "," + this.realParkRecordCheck[0].parkId : "") +
                (this.checkedPark.length ? "," + this.checkedPark.map((item) => item.parkRecordId).join(",") : "");
              this.$axios.post(`${this.domain}/api/pay/cancelRoadCharge`, {
                parkRecordId,
                realRecordId: this.realParkRecordCheck.length ? this.realParkRecordCheck[0].parkId : null,
                payRecordIds,
              });
            } else {
              alert("支付失败，请重新扫码");
            }
          }
        );
      });
    },
    // 微信结算
    wxSettlement() {
      let openId = localStorage.getItem("openId");
      let itemId = localStorage.getItem("itemId");
      let pdaNo = localStorage.getItem("pdaNo");
      let nos = [];
      let arrea_fee = null;
      // 计算欠费
      if (this.checkedPark.length) {
        arrea_fee = this.checkedPark.reduce((accumulator, currentValue) => {
          const valueToAdd = currentValue.amount;
          return math.add(math.bignumber(accumulator), math.bignumber(valueToAdd));
        }, 0);
        arrea_fee = Number(arrea_fee);
      }
      // 支付宝一样
      // 实时订单金额 this.realParkRecordCheck[0].shouldPay : 0;
      let realFee = this.realParkRecordCheck.length ? this.realParkRecordCheck[0].shouldPay : 0;
      // 实时订单单号
      let realNos = this.realParkRecordCheck.length ? this.realParkRecordCheck[0].parkId : null;
      let resultArray = [];
      let diffArray = [];
      if (this.checkedPark.length) {
        // 用了优惠券列表
        resultArray = this.checkCouponList.filter((item1) =>
          this.checkedPark.some((item2) => item2.orderId === item1.orderId)
        );
        // 没用优惠券列表
        diffArray = this.checkedPark.filter((item) => !resultArray.some((item1) => item1.orderId === item.orderId));
      }
      let useCouponList = [];
      let unUseCouponList = [];
      if (resultArray.length) {
        useCouponList = resultArray.map((item) => ({
          parkRecordId: item.parkId + "",
          code: item.id,
          deduction: item.lastFee,
        }));
      }
      if (diffArray.length) {
        unUseCouponList = diffArray.map((item) => ({
          parkRecordId: item.parkId + "",
          code: 0,
          deduction: 0,
        }));
      }
      nos = useCouponList.concat(unUseCouponList);
      let deductions =
        (this.checkCouponList.filter((item) => item.orderId === this.parkData.orderId).length
          ? this.checkCouponList.filter((item) => item.orderId === this.parkData.orderId)[0].lastFee
          : "0") +
        "," +
        (this.checkCouponList.filter((item) => item.orderId === this.realParkRecord.orderId).length
          ? this.checkCouponList.filter((item) => item.orderId === this.realParkRecord.orderId)[0].lastFee
          : "0") +
        (resultArray.length
          ? "," + resultArray.map((item) => item.lastFee).join(",")
          : this.checkedPark.length
          ? "," + Array(this.checkedPark.length).fill(0).join(",")
          : "");
      // 优惠券id
      let codes =
        (this.checkCouponList.filter((item) => item.orderId === this.parkData.orderId).length
          ? this.checkCouponList.filter((item) => item.orderId === this.parkData.orderId)[0].id
          : "0") +
        "," +
        (this.checkCouponList.filter((item) => item.orderId === this.realParkRecord.orderId).length
          ? this.checkCouponList.filter((item) => item.orderId === this.realParkRecord.orderId)[0].id
          : "0") +
        (resultArray.length
          ? "," + resultArray.map((item) => item.id).join(",")
          : this.checkedPark.length
          ? "," + Array(this.checkedPark.length).fill(0).join(",")
          : "");
      if (this.parkData.plateNo) {
        this.$axios
          .post(`${this.domain}/api/pay/unifierOrder`, {
            appId: this.parkData.appId,
            itemId,
            ifCode: "wxpay",
            mchNo: this.parkData.orderId,
            openId,
            body: "停车缴费",
            notify_url: "http://www.baidu.com",
            clientIp: "127.0.0.1",
            totalFee: this.savePaymentAmount,
            parkRecordId: this.parkData.parkRecordId,
            plate_no: this.parkData.plateNo,
            arrearList: nos.length ? JSON.stringify(nos) : null,
            arrearFee: arrea_fee || 0,
            realFee,
            realNos,
            pdaNo,
            deductions: deductions == "" ? null : deductions,
            codes: codes == "" ? null : codes,
          })
          .then((res) => {
            this.$toast.clear();
            if (res.data.code === 200) {
              let data = res.data.data;
              this.weixinPay(data);
            } else if (res.data.code === 9999) {
              Dialog.confirm({
                message: `${res.data.msg}`,
              })
                .then(() => {
                  WeixinJSBridge.call("closeWindow");
                })
                .catch(() => {
                  // on cancel
                });
            } else {
              Dialog.confirm({
                message: `${res.data.msg}`,
              })
                .then(() => {
                  this.$toast.clear();
                })
                .catch(() => {
                  // on cancel
                });
            }
          })
          .catch((error) => {
            this.$toast.clear();
            alert(error);
          });
      } else {
        this.$axios
          .post(`${this.domain}/api/brake/unifierOrder`, {
            appId: this.parkData.appId,
            itemId,
            openId,
            money: this.cash,
            parkId: this.parkData.parkRecordId,
            payCode: "wechat",
          })
          .then((res) => {
            this.$toast.clear();
            let data = res.data.data;
            this.weixinPay(data);
          })
          .catch((e) => {
            this.$toast.clear();
          });
      }
    },
    // 微信收银台
    //解决微信内置对象报错
    weixinPay(data) {
      var that = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener("WeixinJSBridgeReady", that.onBridgeReady(data), false);
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", that.onBridgeReady(data));
          document.attachEvent("onWeixinJSBridgeReady", that.onBridgeReady(data));
        }
      } else {
        that.onBridgeReady(data);
      }
    },
    // 微信内置浏览器类，weChatParameter对象中的参数是3.步骤代码中从后端获取的数据
    onBridgeReady(data) {
      WeixinJSBridge.invoke("getBrandWCPayRequest", data, (res) => {
        let parkRecordId = localStorage.getItem("parkRecordId");
        let payRecordIds =
          parkRecordId +
          (this.realParkRecordCheck.length ? "," + this.realParkRecordCheck[0].parkId : "") +
          (this.checkedPark.length ? "," + this.checkedPark.map((item) => item.parkRecordId).join(",") : "");
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          this.$router.push({
            path: "/inpark/success",
            query: {
              plateNo: this.parkData.plateNo,
              orderIds: payRecordIds,
            },
          });
        } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
          this.$axios.post(`${this.domain}/api/pay/cancelRoadCharge`, {
            parkRecordId,
            realRecordId: this.realParkRecordCheck.length ? this.realParkRecordCheck[0].parkId : null,
            payRecordIds,
          });
          alert("支付已取消，请重新扫码");
        } else {
          this.$router.push("/inpark/fail");
        }
      });
    },
    // 详情
    toDepDetail(id) {
      this.$router.push({
        path: "/inpark/deepdetails",
        query: { id },
      });
    },
    // 跳转
    toDetail() {
      this.detailsedit = !this.detailsedit;
    },
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  width: 100%;
  height: 100%;
}
.parking {
  width: 100%;
  min-height: 100vh;
}

.parkingpay {
  box-sizing: border-box;
  padding: 15px;
  padding-bottom: 60px;
  width: 100%;
  min-height: 100vh;
  background-color: rgb(241, 241, 241);

  .top {
    .topline {
      height: 20px;
      background-image: url("../../assets/topline.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .topmsg {
      padding: 5px 0 15px 15px;
      background-color: #fff;

      .plateno-box {
        display: flex;
        justify-content: space-between;
        .plateno {
          display: flex;
          align-items: center;

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            text-align: center;
            background-color: #2370ff;
            border-radius: 50%;

            img {
              width: 12px;
              height: 10px;
            }
          }

          .num {
            margin-left: 5px;
            font-size: 18px;
            color: #333333;
          }
        }

        .contact {
          margin-right: 10px;
        }
      }

      .cash {
        margin-top: 15px;
        text-align: center;

        .money {
          font-size: 35px;
          color: #2370ff;
          font-weight: bold;
        }

        .parkname {
          font-size: 14px;
          color: #a3a3a3;
        }
      }
    }
  }

  .banner {
    .top-foot {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      padding: 0 15px;
      height: 16px;
      background-color: #fff;

      .leftpoint {
        position: absolute;
        left: 0;
        transform: translate(-50%, 0);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: rgb(241, 241, 241);
      }

      .line {
        margin: 0 auto;
        width: 100%;
        border: 1px dashed #e9e7e7;
      }

      .rightpoint {
        position: absolute;
        right: 0;
        transform: translate(50%, 0);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: rgb(241, 241, 241);
      }
    }

    .banner-msg {
      padding: 17px 15px 10px 15px;
      background-color: #fff;

      .message {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
        color: #a3a3a3;
      }
    }

    .coupon {
      padding: 0 10px;
      padding-bottom: 25px;
      background-color: #fff;
      vertical-align: baseline;

      .coupon-box {
        height: 40px;
        background-color: rgba(235, 87, 87, 0.05);
        border-radius: 5px;
        color: #eb5757;
        font-size: 15px;
        font-weight: 600;
      }

      .img {
        width: 18px;
        height: 18px;

        img {
          width: 18px;
          height: 18px;
        }
      }
    }

    .real-order {
      margin-top: 10px;
      padding: 25px 15px;
      background-color: #fff;

      .real-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
        font-weight: bold;
      }

      .real-msg {
        margin-top: 15px;
        background-color: #f8f8f8;
        padding: 10px 15px;
        border-radius: 5px;
        line-height: 30px;

        .msg-box {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          color: #a3a3a3;

          .value {
            color: #333333;
          }
        }
      }

      .check-coupon {
        display: flex;
        align-items: center;
        margin-top: 15px;

        .coupon {
          padding: 0;
        }
      }
    }

    .arrear-order {
      margin-top: 10px;
      padding: 25px 15px;
      padding-bottom: 0;
      background-color: #fff;

      .arrear-title {
        font-size: 15px;
        font-weight: bold;
      }

      .arrear-box {
        .arrear-msg {
          margin-top: 5px;
          background-color: #f8f8f8;
          padding: 10px 15px;
          border-radius: 5px;
          line-height: 30px;

          .msg-box {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            color: #a3a3a3;

            .value {
              color: #333333;
            }
          }
        }

        .check-coupon {
          display: flex;
          align-items: center;
          margin-top: 15px;

          .coupon {
            padding: 0;
          }
        }
      }

      .showall {
        padding-bottom: 10px;
        text-align: center;
        font-size: 14px;
        color: #a3a3a3;
      }
    }
  }

  .foot-line {
    height: 20px;
    background-image: url("../../assets/footline.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

.tab-bar {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  width: 100%;
  height: 50px;
  background-color: white;
  font-size: 14px;

  button {
    border: none;
    width: 100px;
    height: 50px;
    color: white;
  }
}

.coupon-popup {
  padding-bottom: 80px;

  .getcoupon-list {
    padding: 0 15px;

    .getcoupon-title {
      display: flex;
      justify-content: center;
      padding: 15px 0;

      img {
        width: 176px;
        height: 20px;
      }
    }

    .getcoupon-view {
      display: flex;
      margin-top: 10px;
      width: 100%;
      min-height: 90px;
      background: url("../../assets/getcoupon-listBG.png") no-repeat;
      background-size: 100% 100%;

      .left {
        box-sizing: border-box;
        padding: 10px 0 17px 17px;
        width: 80%;
        min-height: 90px;

        .getcoupon-view-top {
          color: #b45227;
          font-size: 16px;
          font-weight: 600;
        }

        .getcoupon-view-middle {
          font-size: 13px;
          color: #b45227;
        }

        .getcoupon-view-bottom {
          width: 100%;
          margin-top: 6px;
          display: flex;
          flex-wrap: wrap;
          gap: 5px;

          .coupon-count {
            display: flex;
            height: 20px;
            border: 1px solid rgb(254, 174, 94);
            border-radius: 3px;
            font-size: 12px;

            .coupon-count-left {
              padding: 2px 5px;
              background: linear-gradient(90deg, rgb(250, 86, 45), rgb(254, 174, 94) 100%);
              color: #fefbca;
              white-space: nowrap;
            }

            .coupon-count-right {
              padding: 2px 5px;
              color: #ec8e31;
              white-space: nowrap;
            }
          }
        }
      }

      .right {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25%;
        min-height: 90px;

        .getbtn {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: linear-gradient(135deg, rgb(255, 239, 216), rgb(255, 206, 114) 100%);
          border: 2px solid rgba(255, 118, 39, 0.5);
          font-size: 13px;
          color: #cd4104;
          font-weight: 600;
          text-align: center;
          white-space: pre-wrap;
        }
      }
    }
  }

  .gap {
    margin-top: 10px;
    width: 100%;
    height: 10px;
    background-color: #f8f8f8;
  }

  .coupon-list {
    padding: 0 15px;

    .coupon-title {
      display: flex;
      justify-content: center;
      padding-top: 15px;
      font-size: 18px;
    }

    .checkmsg {
      font-size: 15px;
      color: #333333;
    }

    .coupon-view {
      position: relative;
      box-sizing: border-box;
      margin-top: 10px;
      position: relative;
      width: 100%;
      min-height: 90px;
      border-radius: 10px;
      border: 0.5px solid rgba(255, 118, 39, 0.5);

      .expired-label {
        position: absolute;
        top: -4px;
        right: -4px;

        img {
          width: 60px;
          height: 34px;
        }
      }

      .semi-circle-top {
        position: absolute;
        top: -1px;
        left: 84px;
        width: 12px;
        height: 6px;
        background-color: #fff;
        border-radius: 0 0 10px 10px;
        border: 1px solid rgba(255, 118, 39, 0.5);
        border-top: 2px solid #fff;
      }

      .semi-circle-bottom {
        position: absolute;
        bottom: -1px;
        left: 84px;
        width: 12px;
        height: 6px;
        background-color: #fff;
        border-radius: 10px 10px 0 0;
        border: 1px solid rgba(255, 118, 39, 0.5);
        border-bottom: 2px solid #fff;
      }

      .coupon-type {
        position: absolute;
        top: -1px;
        left: -2px;
        width: 60px;
        height: 22px;
        line-height: 22px;
        background: url("../../assets/coupon-typeBG.png") no-repeat;
        color: #ff7627;
        padding-left: 9px;
      }

      .coupon-msg {
        display: flex;
        justify-content: space-between;
        padding: 7px;
        padding-left: 0;

        .coupon-favour-left {
          display: flex;
          flex-direction: column;
          justify-content: end;
          align-items: center;
          margin-top: 8px;
          width: 90px;
          height: 60px;
          text-align: center;
          font-size: 12px;
          color: #ff7627;
          border-right: 1px dashed #ff7627;
        }

        .coupon-favour-right {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: relative;
          padding: 5px 10px;
          width: 240px;
          height: 76px;
          border-radius: 8px;
          background: rgba(255, 118, 39, 0.05);

          .favour-dsc {
            width: 90%;
            color: #333333;
            font-size: 15px;
            font-weight: 600;
          }

          .select {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
          }

          .favour-date {
            display: flex;
            justify-content: space-between;
            color: #a3a3a3;
            font-size: 12px;
          }
        }
      }
    }
  }

  .coupon-define {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    gap: 10px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0px -2px 25px 0px rgba(0, 0, 0, 0.05);

    .cancel {
      flex: 0.5;
      background: rgba(255, 118, 39, 0.1);
      color: #ff7627;
      border: none;
    }

    .submit {
      flex: 1;
      background: linear-gradient(180deg, rgb(255, 167, 99), rgb(250, 114, 12) 100%);
      color: #ffffff;
      border: none;
    }
  }
}

::v-deep .van-cell__value {
  color: #eb5757;
  font-size: 15px;
  font-weight: 400;
}

::v-deep .van-cell__right-icon {
  color: #eb5757;
  font-size: 15px;
  font-weight: 400;
}

::v-deep .van-tag--plain {
  position: absolute;
  top: 50%;
  transform: translate(0, -42%);
  background-color: #fff;
  margin-left: 5px;
  border-radius: 5px;
  color: #eb5757;
}

::v-deep .van-checkbox {
  width: 25px;
}

.active {
  border: 2px solid #ff7627 !important;
}

.active-topround {
  border: 2px solid #ff7627 !important;
  border-top: 3px solid #fff !important;
  top: -2.5px !important;
}

.active-bottomround {
  border: 2px solid #ff7627 !important;
  border-bottom: 3px solid #fff !important;
  bottom: -2.5px !important;
}
</style>
